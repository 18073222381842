import {api} from '../../shared/services/backend-api'

const fetchUserAgreements = async (userID) => {
  const { data } = await api.get(`users/${userID}/agreements`)
  
  return data
}

const updateUserAgreements = async (userData) => {
  const { userID, agreements } = userData
  
  const payload = {
    agreements: agreements.map(({agreementId}) => (agreementId))
  }
  
  return api.put(`users/${userID}/agreements`, payload)
}

const fetchUserPersonalData = async (userID) => {
  const { data } = await api.get(`users/${userID}/personal-data`)
  
  return data
}

const updateUserPersonalData = async (userData) => {
  const {userID, ...payload} = userData
  
  return api.put(`users/${userID}/personal-data`, payload)
}

const resetUserPassword = (email) => {
  const payload = {
    email
  }
  return api.post('reset-password', payload)
}

export {
  fetchUserAgreements,
  updateUserAgreements,
  fetchUserPersonalData,
  updateUserPersonalData,
  resetUserPassword
}
