<template>
<el-dialog
    :width="dialogWidth"
    v-loading="isLoading"
    :visible.sync="showDialog"
    :title="$t('page_profile_data.personal_data.edit_data.modal_title')"
>
  <template #default>
    <el-form
        :rules="formRules"
        size="medium"
        status-icon
        :model="form"
        ref="form"
    >
      <el-form-item prop="name">
        <el-input name="FirstName" :placeholder="$t('forms.first_name')" v-model="form.name" />
      </el-form-item>
      <el-form-item prop="surname">
        <el-input name="LastName" :placeholder="$t('forms.last_name')" v-model="form.surname" />
      </el-form-item>
      <v-button @click="closeDialog">
        {{ $t('page_profile_data.personal_data.edit_data.btn_cancel') }}
      </v-button>
      <v-button type="primary" @click.native.prevent="handleEditUserData">
        {{ $t('page_profile_data.personal_data.edit_data.btn_save') }}
      </v-button>
    </el-form>
  </template>
</el-dialog>
</template>

<script>
import {updateUserPersonalData} from '../../services/profile-account-service'
import VButton from '../../../components/ui/v-button/VButton'
import {mapState} from 'vuex'

const defaultForm = () => {
  return {
    name: '',
    surname: ''
  }
}
export default {
  name: 'UserDataEdit',
  components: {VButton},
  inject: ['responsive'],
  data() {
    return {
      isLoading: false,
      showDialog: false,
      form: defaultForm(),
      formRules: {
        name: [
          {required: true, message: this.$t('validators.field_required')},
        ],
        surname: [
          {required: true, message: this.$t('validators.field_required')},
        ]
      }
    }
  },
  methods: {
    openDialog(userData) {
      this.form = {...userData}
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
      this.form = defaultForm()
    },
    handleEditUserData() {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.isLoading = true
        const payload = {
          ...this.form,
          userID: this.user.uuid
        }
        updateUserPersonalData(payload)
          .then(() => {
            this.$emit('user-data-updated', this.form)
            this.closeDialog()
            this.$message({
              type: 'success',
              message: `${this.$t('page_profile_data.personal_data.edit_data.messages.success')}`
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `${this.$t('page_profile_data.personal_data.edit_data.messages.error')}`
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    }
  },
  computed: {
    ...mapState('authState', {
      user: state => state.user
    }),
    dialogWidth() {
      return this.responsive.isXs ? '95%' : '50%'
    }
  }
}
</script>

<style scoped>

</style>
