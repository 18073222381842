<template>
  <div v-loading="isLoading">
    <el-card class="mt-1">
      <template #header>
        <h2 class="heading">
          {{ $t('page_profile_data.personal_data.title') }}
        </h2>
      </template>
      <el-descriptions :column="1">
        <el-descriptions-item
            :label="$t('page_profile_data.personal_data.labels.name')"
        >
          <span class="font-bold">
            {{ personalData.name }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item
            :label="$t('page_profile_data.personal_data.labels.surname')"
        >
          <span class="font-bold">
            {{ personalData.surname }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item
            :label="$t('page_profile_data.personal_data.labels.email')"
        >
          <span class="font-bold">
            {{ personalData.email }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
      <v-button class="mt-1" type="primary" @click="editUserPersonalData">
        {{ $t('page_profile_data.personal_data.btn_edit') }}
      </v-button>
      <UserDataEdit ref="userEditDataModal" @user-data-updated="updateUserData"/>
    </el-card>
    <el-card class="mt-1">
      <template #header>
        <h2 class="heading">
          {{ $t('page_profile_data.security.title') }}
        </h2>
      </template>
      <el-descriptions :column="1">
        <el-descriptions-item
            :label="$t('page_profile_data.security.labels.password')"
        >
          <span class="font-bold">
            {{ $options.HIDDEN_PASSWORD }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
      <v-button :loading="isResetInProgress" class="mt-1" type="primary" @click="resetPassword">
        {{ $t('page_profile_data.security.btn_reset') }}
      </v-button>
    </el-card>
    <el-card class="mt-1">
      <template #header>
        <h2 class="heading">
          {{ $t('page_profile_data.agreements.title') }}
        </h2>
      </template>
      <div class="agreement-list">
        <div
            class="agreement-list__item"
            v-for="(item, idx) in agreementList"
            :key="idx"
        >
          <el-checkbox
              class="agreement-checkbox"
              v-model="item.isAssigned"
              :label="item"
          >
            {{ item.agreementName }}
          </el-checkbox>
        </div>
      </div>
      <v-button @click="updateAgreements" class="mt-1" type="primary">
        {{ $t('page_profile_data.agreements.btn_save') }}
      </v-button>
    </el-card>
  </div>
</template>

<script>
import VButton from '../../components/ui/v-button/VButton'
import {
  fetchUserAgreements,
  fetchUserPersonalData,
  resetUserPassword,
  updateUserAgreements
} from '../services/profile-account-service'
import UserDataEdit from './user-data-edit/UserDataEdit'
import {mapState} from 'vuex'
import {NOT_FOUND_STATUS_CODE} from '../../shared/constants/backend'
import {USER_LOGIN_ROUTE_NAME} from '../../shared/constants/app-routes-names'


export default {
  name: 'ProfileUserData',
  components: {VButton, UserDataEdit},
  HIDDEN_PASSWORD: '•••••••••••••',
  created() {
    this.fetchProfileData()
  },
  data() {
    return {
      personalData: {
        name: '',
        surname: '',
        email: ''
      },
      agreementList: [],
      isLoading: true,
      isResetInProgress: false,
    }
  },
  methods: {
    resetPassword() {
      this.isResetInProgress = true
      resetUserPassword(this.user.email)
        .then(() => {
          this.$message({
            type: 'success',
            message: `${this.$t('page_profile_data.security.messages.reset_password_success')}`
          })
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `${this.$t('page_profile_data.security.messages.reset_password_failed')}`
          })
        })
        .finally(() => {
          this.isResetInProgress = false
        })
    },
    fetchProfileData() {
      const userID = this.user?.uuid
      this.isLoading = true
      Promise.all([
        fetchUserAgreements(userID),
        fetchUserPersonalData(userID)
      ])
        .then(([agreementList,  personalData]) => {
          this.agreementList = agreementList
          this.personalData = personalData
        })
        .catch((e) => {
          if (e.status === NOT_FOUND_STATUS_CODE) {
            this.$router.push({
              name: USER_LOGIN_ROUTE_NAME,
              params: {
                back: true
              }
            })
            this.$message({
              type: 'error',
              message: `${this.$t('alerts.session_expired')}`
            })
            return
          }
          this.$message({
            type: 'error',
            message: `${this.$t('alerts.common_error', {status: e.status})}`
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateUserData(userData) {
      this.personalData = { ...this.personalData, ...userData }
    },
    editUserPersonalData() {
      this.$refs.userEditDataModal.openDialog(this.personalData)
    },
    updateAgreements() {
        this.isAgreementLoading = true
        const payload = {
          agreements: this.selectedAgreements,
          userID: this.user.uuid
        }
      updateUserAgreements(payload)
          .then(() => {
            this.$message({
              type: 'success',
              message: `${this.$t('page_profile_data.agreements.messages.success')}`
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `${this.$t('page_profile_data.agreements.messages.error')}`
            })
          })
          .finally(() => {
            this.isAgreementLoading = false
          })
    },
  },
  computed: {
    ...mapState('authState', {
      user: state => state.user
    }),
    selectedAgreements() {
      return this.agreementList.filter((item) => item.isAssigned)
    }
  }
}
</script>

<style scoped lang="scss">
.agreement-list {
  &__item {
    margin-bottom: 1em;
  }
}
</style>
